import { makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles<Theme, { ColorCode: string }>((theme) => ({
  container: {
    width: "100%",
    height: "60rem",
    backgroundColor: ({ ColorCode }) => ColorCode,
    marginTop: "-5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down(1240)]: {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  left: {
    "& img": {
      height: "24rem",
      [theme.breakpoints.down(1240)]: {
        marginTop: "5.5rem",
        height: "15.18rem",
      },
    },
  },
  right: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "3.5rem",
    [theme.breakpoints.down(1240)]: {
      paddingTop: "2.5rem",
      gap: "2.5rem",
    },
  },
  congratsText: {
    fontSize: "1.5rem",
    fontFamily: "Montserrat",
    fontWeight: "bold",
    color: "#FFFFFF",
    textAlign: "center",
  },
  descriptionText: {
    fontSize: "1rem",
    fontFamily: "Montserrat",
    color: "#FFFFFF",
    width: "20rem",
    lineHeight: "1.75",
    textAlign: "center",
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.875rem",
      margin: "0 2rem 0 2rem",
    },
  },
  mail: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
  },
  mailText: {
    fontSize: "1rem",
    fontFamily: "Nunito",
    fontWeight: 500,
    color: "#FFFFFF",
    textAlign: "center",
    [theme.breakpoints.down(1240)]: {
      fontSize: "0.875rem",
    },
  },
  buttonContainer: {
    width: "100%",
    padding: "0 2rem 0 2rem",
  },
  button: {
    width: "100%",
  },
}));
export default useStyles;
