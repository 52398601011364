import useGeneralStyles from "@components/Services/styles";
import React, { useEffect, useState } from "react";
import useStyles from "./PaymentStatus.styles";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { ButtonPrimary } from "@components/Reusables/ButtonPrimary";
import { useCurrentUser } from "@apollo";
interface creditsProps {
  image: string;
  titleText: string;
  descriptionText: string;
  backgroundColorCode: string;
  backRouteFunction: () => void;
  showMail: boolean;
}

const PaymentStatus: React.FC<creditsProps> = ({
  image,
  titleText,
  descriptionText,
  backgroundColorCode,
  backRouteFunction,
  showMail,
}) => {
  const classes = useStyles({ ColorCode: backgroundColorCode });
  const { user: currentUser } = useCurrentUser();
  const [ShowMailText, setShowMailText] = useState(false);
  useEffect(() => {
    setShowMailText(showMail);
  });
  return (
    <>
      <div className={classes.container}>
        <div className={classes.left}>
          <img src={image} alt="" />
        </div>
        <div className={classes.right}>
          <p className={classes.congratsText}>{titleText}</p>
          <p className={classes.descriptionText}>{descriptionText}</p>
          {ShowMailText && (
            <div className={classes.mail}>
              <EmailOutlinedIcon htmlColor="#FFFFFF" />
              <p className={classes.mailText}>{currentUser?.email}</p>
            </div>
          )}
          <div className={classes.buttonContainer}>
            <ButtonPrimary
              className={classes.button}
              onClick={backRouteFunction}
              text="Listo"
              noDegraded
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentStatus;
